module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-93aa47730b/3/buildhome/.yarn/berry/cache/gatsby-plugin-manifest-npm-5.13.1-2036e9f84e-10c0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DNA - Designing Next-Gen Architecture","short_name":"DNA - Designing Next-Gen Architecture","start_url":"/","background_color":"#000","theme_color":"#000","display":"minimal-ui","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"903ba06d17962577ec31a06296954d16"},
    },{
      plugin: require('../../../../../buildhome/.yarn/berry/cache/gatsby-plugin-anchor-links-npm-1.2.1-7db63a8548-10c0.zip/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-f1f419d97c/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
