import "./src/styles/global.css";

console.log(
  `%c                                    
  █████████
    ██████████
    ██████████
    ██████████
  █████████ █                                 
`,
  "font-size: 20px;  color: #EE4A34;",
);
